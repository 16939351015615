
import {
  Vue,
  Component,
  PropSync,
  Prop,
} from 'vue-property-decorator';
import { TimePickerProps } from '@/types/vjsf';

@Component({
  name: 'TimePicker',
})
export default class TimePicker extends Vue {
  @PropSync('dateTimeString', { required: true })
  private dateTime!: string|null;

  @Prop({ required: true })
  private readonly options!: TimePickerProps | undefined;

  get time(): string|null {
    if (this.dateTime === null) {
      return '';
    }
    if (this.dateTime?.includes('T')) {
      const time = this.dateTime.split('T')[1];
      return time.split('Z')[0];
    }
    return this.dateTime.split('Z')[0];
  }

  set time(input: string|null) {
    let date = '';
    if (input === null) {
      this.dateTime = '';
    } else {
      if (this.dateTime?.includes('T')) {
        date = `${this.dateTime.split('T')[0]}T`;
      }

      this.dateTime = input !== ''
        ? `${date}${input}Z`
        : '';
    }
  }
}

